import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActionModel } from '../action/models/ActionModel';
import { FilterGroupModel } from '../filters/models/filter.model';
import { ItemEmitterModel, ItemModel } from '../item.model';
import {
  ApiResponseErrorModel,
  ApiResponseInterface,
  ApiResponseSuccessModel,
  ApiResponseTypesMainTableEnum,
} from '../models/ApiResponse.model';
import { CustomButtonClickedEvent } from '../models/CustomButtonEvent.model';
import { CustomMessageFromTable } from '../models/CustomMessageFromTable';
import { DuplicateByDataEmittedModel } from '../models/DuplicateByDataEmitted.model';
import { RuleModel } from '../models/rule.model';
import { ColumnComponentEventInterface } from '../table-display/columns-switch/columns/editable-column.component';

@Injectable({
  providedIn: 'root',
})
export class CommunicationService implements OnDestroy {
  itemChanged$: Subject<ItemModel> = new Subject();
  revertAction$: Subject<ActionModel> = new Subject();
  restoreAction$: Subject<ActionModel> = new Subject();
  apiResponse$: Subject<ApiResponseInterface> = new Subject();
  apiError$: Subject<ApiResponseErrorModel> = new Subject();
  apiSuccess$: Subject<ApiResponseSuccessModel> = new Subject();
  // RULES
  ruleEmitted$: Subject<RuleModel> = new Subject();
  customButtonClicked$: Subject<CustomButtonClickedEvent> = new Subject();
  columnComponentEvent$: Subject<ColumnComponentEventInterface> = new Subject();
  aiGeneratedClicked$: Subject<ItemEmitterModel> = new Subject();
  initRemove$: Subject<ItemModel[]> = new Subject();
  initRemoveByFilters$: Subject<FilterGroupModel[]> = new Subject();
  navigationClicked$: Subject<ItemEmitterModel> = new Subject();
  // DUPLICATES
  initDuplicate$: Subject<ItemModel> = new Subject();
  initMultiDuplicate$: Subject<ItemModel[]> = new Subject();
  initDuplicateBy$: Subject<DuplicateByDataEmittedModel> = new Subject();

  customMessage$: Subject<CustomMessageFromTable> = new Subject();

  itemAdded$: Subject<ItemModel> = new Subject();
  customEventOnColumn$: Subject<ItemEmitterModel> = new Subject();
  cellClicked$: Subject<ItemEmitterModel> = new Subject();
  itemInfoClicked$: Subject<ItemModel> = new Subject();
  openPhotoCustomEdit$: Subject<ItemEmitterModel> = new Subject();
  customButtonOpened$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  navigateTo$: Subject<ItemModel> = new Subject();
  tabsChanged$: Subject<void> = new Subject();
  private onDestroy$: Subject<void> = new Subject();

  constructor() {
    this.apiResponse$.pipe(takeUntil(this.onDestroy$)).subscribe((res) => this.mapApiResponse(res));
  }

  mapApiResponse(response: ApiResponseInterface) {
    const { type, message, invalidFields } = response;

    switch (type) {
      case ApiResponseTypesMainTableEnum.success: {
        const apiSuccess = new ApiResponseSuccessModel(type, message, this);
        this.apiSuccess$.next(apiSuccess);
        break;
      }
      case ApiResponseTypesMainTableEnum.error: {
        const apiError = new ApiResponseErrorModel(type, message, this, invalidFields);
        this.apiError$.next(apiError);
        break;
      }
      default: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const exhaustCheck: never = type;
      }
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }
}
