import { Injectable, OnDestroy, inject } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ItemModel } from '../item.model';
import { ChangeModel, SingleChangeInterface } from '../models/change.model';
import { CommunicationService } from './communication.service';
import { ConfigService } from './config.service';
import { HelperService } from './helper.service';
import cloneDeep from 'lodash/cloneDeep';

@Injectable({
  providedIn: 'root',
})
export class ChangesService implements OnDestroy {
  newChange$: Subject<ChangeModel> = new Subject();
  clearChanges$: Subject<void> = new Subject();
  clearUpdates$: Subject<void> = new Subject();
  toUpdate: ItemModel[] = [];
  toUpdate$: BehaviorSubject<ItemModel[]> = new BehaviorSubject(this.toUpdate);
  changeList: ChangeModel[] = [];
  changeList$: BehaviorSubject<ChangeModel[]> = new BehaviorSubject(this.changeList);
  changeToRevert: ChangeModel | null = null;
  changeToRestore: ChangeModel | null = null;
  changesToRevert: ChangeModel[] = [];
  changesToRestore: ChangeModel[] = [];
  singleChange$: Subject<SingleChangeInterface> = new Subject();
  private onDestroy$ = new Subject<void>();
  private lastSequence = 0;

  private communicationService = inject(CommunicationService);
  private configService = inject(ConfigService);
  private helperService = inject(HelperService);

  constructor() {
    this.clearChanges$
      .pipe(
        takeUntil(this.onDestroy$),
        tap(() => this.toUpdate$.next([])),
        tap(() => (this.changesToRevert = [])),
        tap(() => (this.changeToRevert = null)),
        tap(() => (this.changesToRestore = [])),
        tap(() => (this.changeToRestore = null)),
      )
      .subscribe(() => this.changeList$.next([]));

    this.newChange$
      .pipe(
        takeUntil(this.onDestroy$),
        tap((change) => this.calculateLastSequence(change)),
        tap(() => (this.changesToRestore = [])),
        tap(() => (this.changeToRestore = null)),
        tap((change) => this.changesToRevert.unshift(change)),
        tap(() => (this.changeToRevert = this.changesToRevert[0])),
        tap((change) => {
          if (change.madeByEdit) {
            change.itemsAfterChange!.forEach((item) => this.addToUpdate(item));
          } else {
            // logika dodawania nowej akcji globalnej
          }
        }),
      )
      .subscribe((change) => this.changeList$.next([...this.changeList, change]));

    this.toUpdate$
      .pipe(
        takeUntil(this.onDestroy$),
        tap((items) => (this.toUpdate = items)),
        // tap(items => console.log(items)),
      )
      .subscribe();

    this.clearUpdates$.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.toUpdate$.next([]));

    this.changeList$
      .pipe(
        takeUntil(this.onDestroy$),
        tap((changeList) => (this.changeList = changeList)),
        // tap(console.log),
      )
      .subscribe();
  }

  addToUpdate(item: ItemModel) {
    if (this.helperService.isInArray(item, this.toUpdate)) {
      this.toUpdate[this.helperService.returnIndexInArray(item, this.toUpdate)] = item;
    } else {
      this.toUpdate.push(item);
    }
    this.communicationService.itemChanged$.next(item);
    this.toUpdate$.next(this.toUpdate);
  }

  removeFromUpdate(item: ItemModel): boolean {
    this.communicationService.itemChanged$.next(item);
    if (this.checkDataHaveChanges(item)) {
      return false;
    }
    this.helperService.removeElementFromArray(item, this.toUpdate);
    this.toUpdate$.next(this.toUpdate);
    return true;
  }

  restoreChange(): boolean {
    if (!this.changeToRestore) {
      return false;
    }

    if (this.changeToRestore.madeByEdit) {
      this.changeToRestore.itemsAfterChange!.forEach((item) => this.addToUpdate(item));
    } else {
      this.communicationService.restoreAction$.next(this.changeToRestore.globalAction!);
    }
    this.changesToRevert.unshift(this.changeToRestore);
    this.changesToRestore.shift();
    this.setActualChanges();
    return true;
  }

  revertChange(): boolean {
    if (!this.changeToRevert) {
      return false;
    }
    this.changesToRevert.shift();

    if (this.changeToRevert.madeByEdit) {
      this.changeToRevert.itemsBeforeChange!.forEach((item) => this.removeFromUpdate(item));
    } else {
      this.communicationService.revertAction$.next(this.changeToRevert.globalAction!);
    }
    this.changesToRestore.unshift(this.changeToRevert);
    this.setActualChanges();
    return true;
  }

  createChange(items: ItemModel[], param: string, newValue: any[]): ChangeModel {
    const column = this.configService?.config?.columns[param] || null;
    const prepareItem = (item: ItemModel, value: any) => ({ ...item, [param]: this.prepareValue(param, value) });

    let newItems: ItemModel[];

    if (column?.columnKeyToFilter) {
      const paramColumnKeyToFilter = column.columnKeyToFilter as string;

      newItems = items.map((item, i) => ({
        ...prepareItem(item, newValue[i]),
        [paramColumnKeyToFilter]: this.prepareValue(paramColumnKeyToFilter, newValue[i]),
      }));
    } else {
      newItems = items.map((item, i) => prepareItem(item, newValue[i]));
    }

    return new ChangeModel({
      itemsBeforeChange: items,
      itemsAfterChange: newItems,
    });
  }

  createSingleChange(item: any, columnName: string, value: any): SingleChangeInterface {
    const currentItem = cloneDeep(item);
    const column = this.configService?.config?.columns[columnName] || null;
    const columnKeyToFilter = column?.columnKeyToFilter ? (column.columnKeyToFilter as string) : columnName;
    const newValue = this.prepareValue(columnKeyToFilter, value);
    const changedData = { [columnKeyToFilter]: newValue };
    let oldValue;

    if (item) {
      oldValue = cloneDeep(currentItem[columnName]) || null;
      // @TODO: może dać tu jakąś flagę do wyświetlenia na froncie, że dana komórka została zmieniona?
      item[columnName] = value;
    }

    return {
      changedData,
      columnName,
      item: currentItem,
      newValue,
      oldValue,
    };
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  private checkDataHaveChanges(item: ItemModel): boolean {
    const res = this.changesToRevert.find((change) => change.itemIsLocatedInChange(item));
    return !!res;
  }

  private calculateLastSequence(change: ChangeModel) {
    if (change.madeByAction) {
      this.lastSequence++;
    } else {
      this.incrementIfPreviousChangeWasByAction();
    }
    change.sequence = this.lastSequence;
  }

  private incrementIfPreviousChangeWasByAction() {
    const { changeList } = this;
    const lastIndex = changeList.length - 1;
    const lastChange = changeList[lastIndex];

    if (lastChange && lastChange.madeByAction) {
      this.lastSequence++;
    }
  }

  private prepareValue(paramKey: string, value: any): any {
    const column = this.configService?.config?.columns[paramKey] || null;
    return column && column.responseMapping ? column.responseMapping(value) : value;
  }

  private setActualChanges() {
    this.changeToRestore = this.changesToRestore[0] ? this.changesToRestore[0] : null;
    this.changeToRevert = this.changesToRevert[0] ? this.changesToRevert[0] : null;
  }
}
