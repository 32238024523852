import { Injectable, OnDestroy, inject } from '@angular/core';
import { cloneDeep, isEqual } from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { first, skip, takeUntil, tap } from 'rxjs/operators';
import { ActionService } from '../action/action.service';
import { TableConfigurationModel } from '../models/TableConfiguration.model';
import { ColumnModel, TableConfigurationInterface } from '../public-api';
import { ChangesService } from '../services/changes.service';
import { ConfigService } from '../services/config.service';
import { HelperService } from '../services/helper.service';
import { PaginationService } from '../table-display/pagination/pagination.service';
import { FilterLogicOperatorEnum } from './FilterLogicOperatorEnum';
import { FilterFactoryService } from './filtersFactory.service';
import { FilterGroupModel, FilterInStorageModel, FilterModel, FiltersGroupParamsInterface } from './models/filter.model';

@Injectable({
  providedIn: 'root',
})
export class FilterService implements OnDestroy {
  filtersInStorage: FilterInStorageModel[] = [];
  filtersInStorage$: BehaviorSubject<FilterInStorageModel[]> = new BehaviorSubject(this.filtersInStorage);
  recentFiltersInStorage$ = new BehaviorSubject<FilterInStorageModel[]>([]);
  groups: FilterGroupModel[] = [];
  groups$: BehaviorSubject<FilterGroupModel[]> = new BehaviorSubject(this.groups);
  filtersCounter$: BehaviorSubject<number> = new BehaviorSubject(0);
  private unSubscribe$ = new Subject<void>();

  private filterFactoryService = inject(FilterFactoryService);
  private configService = inject(ConfigService);
  private helperService = inject(HelperService);
  private actionService = inject(ActionService);
  private changesService = inject(ChangesService);
  private paginationService = inject(PaginationService);

  constructor() {
    this.configService.config$
      .pipe(
        first(),
        tap((config) => this.mapFiltersOnInit(config)),
        tap((config) => this.getStorageData(config)),
      )
      .subscribe();

    this.groups$
      .pipe(
        takeUntil(this.unSubscribe$),
        tap(() => this.paginationService.reactToFilterChange()),
        tap(() => this.actionService.clearActions$.next()),
        tap(() => this.changesService.clearChanges$.next()),
        tap(() => this.countFilters()),
        skip(2),
      )
      .subscribe();
  }

  checkIfFiltersAreAllowedInMassOperations(): boolean {
    const areForbidden = this.groups.some((group) =>
      group.filters.some((filter) => {
        const column = this.configService.config.columns[filter.param];
        return column.disabledIn && column.disabledIn.usingAsFilterInMassOperations;
      }),
    );
    return !areForbidden;
  }

  updateGroup(group: FilterGroupModel) {
    this.helperService.isInArray(group, this.groups);
    this.groups[this.helperService.returnIndexInArray(group, this.groups)] = group;
    group.filters[0].operator = FilterLogicOperatorEnum.none;
    this.configService.changeFilters(this.groups);
    this.groups$.next(this.groups);
  }

  deleteGroup(group: FilterGroupModel) {
    this.helperService.isInArray(group, this.groups);
    this.groups.splice(this.helperService.returnIndexInArray(group, this.groups), 1);
    this.groups$.next(this.groups);
    this.configService.changeFilters(this.groups);
  }

  addGroup(group: FilterGroupModel) {
    this.groups.push(group);
    this.groups$.next(this.groups);
    this.configService.changeFilters(this.groups);
  }

  addFilterToLastGroup(filterToAdd: FilterModel) {
    if (!this.groups[0]) {
      filterToAdd.operator = FilterLogicOperatorEnum.none;
      this.addGroup({
        operator: FilterLogicOperatorEnum.none,
        filters: [filterToAdd],
      });
    } else {
      filterToAdd.operator = FilterLogicOperatorEnum.and;
      this.groups[this.groups.length - 1].filters.push(filterToAdd);
      this.groups$.next(this.groups);
      this.configService.changeFilters(this.groups);
    }
  }

  resetFilters() {
    if (this.actionService.globalActions[0]) {
      // eslint-disable-next-line no-alert
      window.alert('You have to save before change filters');
      return;
    }
    this.groups = [];
    this.groups$.next(this.groups);
    this.configService.changeFilters(this.groups);
  }

  saveRecentFiltersInMemory() {
    const recentFilterMemorySize = 5;
    const activeFilters = cloneDeep(this.groups);
    const actualRecentFilters = cloneDeep(this.recentFiltersInStorage$.getValue());
    if (activeFilters.length === 0) {
      // console.log('zatrzymuje bo pusty filtr');
      return;
    }

    const indexOfExistingFilter = actualRecentFilters.findIndex((recentFilter) => isEqual(recentFilter.filters, activeFilters));
    if (indexOfExistingFilter >= 0) {
      // equal found? => move it to the first place and save in storage
      const filterToUse = actualRecentFilters[indexOfExistingFilter];
      actualRecentFilters.splice(indexOfExistingFilter, 1);
      actualRecentFilters.unshift(filterToUse);
      this.configService.config.memory!.filtersRecent = actualRecentFilters;
      this.recentFiltersInStorage$.next(actualRecentFilters);
      return;
    }

    // new filter so save it in storage
    actualRecentFilters.unshift({ filters: activeFilters, name: Date.now().toString() });
    actualRecentFilters.splice(recentFilterMemorySize, 1);
    this.configService.config.memory!.filtersRecent = actualRecentFilters;
    this.recentFiltersInStorage$.next(actualRecentFilters);
  }

  saveFilterToStorage(name: string, filterGroups: FilterGroupModel[]) {
    this.filtersInStorage.push({ name, filters: [...filterGroups] });
    this.saveInStorage();
  }

  getFilterGroupsForConfig(
    baseConfig: TableConfigurationInterface,
    filtersParams: FiltersGroupParamsInterface,
    operator: FilterLogicOperatorEnum = FilterLogicOperatorEnum.none,
  ): FilterGroupModel[] | null {
    const config = cloneDeep(baseConfig);
    const filters: FilterModel[] = [];

    if (!config?.columns) {
      console.warn('No config columns');
    } else {
      Object.entries(filtersParams).forEach(([key, obj]) => {
        const column: ColumnModel = config.columns[key];

        if (column) {
          column.param = key;
          const filter: FilterModel = this.filterFactoryService.createFilter(
            column,
            obj.type,
            obj.value,
            obj.operator || (filters.length ? FilterLogicOperatorEnum.and : FilterLogicOperatorEnum.none),
          );

          filter && filters.push(filter);
        } else {
          console.warn(`Missing init filter column: ${key}`);
        }
      });
    }

    return filters.length ? [{ filters, operator }] : null;
  }

  setInitFiltersForConfig(config: TableConfigurationInterface, filtersParams: FiltersGroupParamsInterface): TableConfigurationInterface {
    config.initFilters = this.getFilterGroupsForConfig(config, filtersParams)!;

    return config;
  }

  useFilterFromStorage(storageFilters: FilterInStorageModel) {
    this.setFilters(cloneDeep(storageFilters.filters));
  }

  deleteFilterInStorage(filt: FilterInStorageModel) {
    const index = this.filtersInStorage.indexOf(filt);
    if (index > -1) {
      this.filtersInStorage.splice(index, 1);
    }
    this.saveInStorage();
  }

  returnFilterIndexFromGroup(filt: FilterModel, group: FilterGroupModel): number {
    return group.filters.findIndex((obj) => obj.param === filt.param && obj.symbol === filt.symbol && obj.value === filt.value);
  }

  setFilters(groups: FilterGroupModel[]) {
    this.groups = groups;
    this.groups$.next(this.groups);
    this.configService.changeFilters(this.groups);
  }

  countFilters(): void {
    let filtersCount = 0;
    this.groups.forEach((el) => (filtersCount += el.filters.length));
    this.filtersCounter$.next(filtersCount);
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
  }

  private getStorageData(config: TableConfigurationModel) {
    this.filtersInStorage = config.memory!.filterSets || [];
    this.filtersInStorage$.next([...this.filtersInStorage]);

    const recent = config.memory!.filtersRecent || [];
    recent.forEach((element: FilterInStorageModel) => {
      element.filters.forEach(
        (group) =>
          (group.filters = group.filters.map((e) => {
            const column = this.configService.config.getColumn(e.param);
            return this.filterFactoryService.createFilter(column, e.symbol, e.value, e.operator, e.case_sensitive, e.additional_sources);
          })),
      );
    });
    this.recentFiltersInStorage$.next(recent);
  }

  private saveInStorage() {
    this.configService.config.memory!.filterSets = this.filtersInStorage;
    this.filtersInStorage$.next(this.filtersInStorage);
  }

  private mapFiltersOnInit(config: TableConfigurationModel) {
    if (!config.activeFilters) {
      return;
    }

    config.activeFilters = config.activeFilters.map((group) => {
      group.filters = group.filters.map((f) => {
        const column = this.configService.config.getColumn(f.param);
        return this.filterFactoryService.createFilter(
          column,
          f.symbol,
          f.value,
          f.operator,
          f.case_sensitive,
          f.additional_sources,
          f.project_connection_id,
          f.start_date,
          f.end_date,
        );
      });
      return group;
    });

    this.setFilters(config.activeFilters);
  }
}
